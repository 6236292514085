<template>
    <div><div id="purchaseDetail">
                  

                                            <div class="contentPD">
                                                <div class="sec1_cpd">
                                                        <div class="sec1_cpd_titles">
                                                            <p class="cpdt1">NÚMERO DEL PEDIDO</p>
                                                            <p class="cpdt2">FECHA</p>
                                                            <p class="cpdt3">TOTAL</p>
                                                            <p class="cpdt4">MÉTODO DE PAGO</p>
                                                        </div>
                                                        <div class="sec1_cpd_data">
                                                            <p class="cpdd1">{{data.purchase_num}}</p> <!--  pendiente agregar numero pedido -->
                                                            <p class="cpdd2">{{data.purchase_date}}</p> <!--  pendiente agregar fecha -->
                                                            <p class="cpdd3">${{data.total}}</p>
                                                            <p class="cpdd4">{{data.method_payment}}</p>
                                                        </div>
                                                </div>
                                                <div class="tablaDP">
                                                    <div class="headerTPD">
                                                        <p>DETALLES DEL <span clr>PEDIDO</span> </p>
                                                        
                                                    </div>
                                                    <div class="contentTPD">
                                                        <div class="sec1_cnt">
                                                            <div class="sec1_cnt_prd">
                                                                <div class="dflx">
                                                                    <p class="sctitle_r cntt1">PRODUCTO</p>
                                                                    <p class="sctitle_r">PRECIO</p>
                                                                </div>
                                                                <div class="items_cnt">
                                                                    <!-- productos comprados -->
                                                                    <div v-for="(item, key) in data.detail" :key="key" class="item_cnt dflx">
                                                                        <div class="item_cntinf">
                                                                            <p class="pbl">{{item.name}}</p> 
                                                                            <p>{{item.size}} | {{item.color}}</p>
                                                                            <!--<p>{{item.descripcion}}</p>-->
                                                                            <p class="pbl">{{item.quantity}}</p>
                                                                        </div>
                                                                        <div class="item_cntprc">
                                                                            <p>{{item.price}}</p>
                                                                        </div>
                                                                        
                                                                    </div >
                                                                    
                                                                    

                                                                </div>
                                                            </div>
                                                            <div class="border_cnt">

                                                            </div>
                                                            <div class="sec2_cnt_sub">
                                                                    <div class="dflx  cntsub">
                                                                        <p class="sctitle_r sect2_cntt1">SUBTOTAL</p>    
                                                                            <p>${{data.subtotal}}</p>
                                                                    
                                                                </div>
                                                                <div class="dflx">

                                                                        <p class="sctitle_r sect2_cntt1"> ENVÍO</p>
                                                                        <p> ${{data.envio}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="border_cnt">

                                                            </div>
                                                            <div class="sec3_cnt_mtd">
                                                                    <div class="dflx cntmtd">
                                                                        <p class="sctitle_r sect3_cntt1">MÉTODO <br> DE PAGO</p>
                                                                        <p class="sect3_cntt2">{{data.method_payment}}</p>
                                                                    
                                                                </div>
                                                                <div class="dflx">

                                                                        <p class="sctitle_r sect3_cntt1">TOTAL</p>
                                                                        <p class="sect3_cntt2"> ${{data.total}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="border_cnt_b"> </div>
                                                        <div class="sec2_cnt_fac" v-if="data.billing != 'No se encontraron coincidencias'"> <!-- si existe facturacion  -->
                                                                <div class="dflx">
                                                                    <p class="sctitle_r .pbl fact1">RAZÓN SOCIAL</p>
                                                                    <p>{{data.billing.reason_social}}</p>
                                                                </div>
                                                                <div class="dflx">
                                                                    <p class="sctitle_r .pbl fact1">RFC</p>
                                                                    <p>{{data.billing.rfc}}</p>
                                                                </div>
                                                                <div class="dflx">
                                                                    <p class="sctitle_r .pbl fact1">DIRECCIÓN FISCAL</p>
                                                                    <p>{{data.billing.fiscal_address}}</p>
                                                                </div>
                                                                <div class="dflx">
                                                                    <p class="sctitle_r  .pbl fact1">CÓDIGO POSTAL</p>
                                                                    <p>{{data.billing.postal_code}}</p>
                                                                </div>
                                                                <div class="dflx">
                                                                    <p class="sctitle_r .pbl fact2">CORREO ELECTRÓNICO</p>
                                                                    <p>{{data.billing.email}}</p>
                                                                </div>

                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
    </div>
</template>

<script>

import {mapActions} from 'vuex';

export default {
    name: "DetallePedido",
    props:{
        id:{
            type:Number,
            default: 0
        }
    },
    
    created(){
        let payload = {
        id: this.id,
        option: "pedido",
        };
        this.getInfoById(payload);
        },
    computed:{
         data() {
            return this.$store.getters["main/data"];
        },
    },
    methods:{
        ...mapActions("main", ["getInfoById"]),
         wait: function () {
        setTimeout(() => this.$router.go(), 200);
        },
    }
}
</script>
<style scoped>

 #purchaseDetail{
        font-family: "Rajdhani";
        margin-left: 8.5vw;
    }
    .headerPD{
        height: 10.260416666666666VW;
        margin-top: 4.583333333333333VW;
        background: linear-gradient(90deg, #B70D1D 0%, #032A4B 100%);
    }.headerPD h1{
        text-align: center;
        color: var(--color-7);
        font-weight: 700;
        font-size: 3.75VW;
        padding-top: 0.78125VW;
    }.headerPD p{
        text-align: center;
        color: var(--color-4);
        font-size: 1.875VW;
    }.sec1_cpd_titles{
        margin-top: 3.4895833333333335VW;
        display: flex;
           margin-left: 9.614583VW;
        color: var(--color-7);
    }.sec1_cpd_titles p{
        font-size: 1.25VW;
        font-weight: 700;
        text-align: center;
        text-decoration: underline;
    }.cpdt1{
        width: 14.531250000000002VW;
        margin-right: 7.291666666666667VW;
    }.cpdt2{
        width: 2.96875VW;
        margin-right: 11.041666666666666VW;
    }.cpdt3{
        width: 2.8125VW;
        margin-right: 11.458333333333332VW;
    }.cpdt4{
        width: 12.239583333333332VW;
    }.sec1_cpd_data{
        display: flex;
           margin-left: 9.614583VW;
        color: var(--color-1);
    }.sec1_cpd_data p{
        font-size: 1.25VW;
        text-align: center;
        font-family: "Rajdhani";
        font-weight: 600;
    }.cpdd1{
          width: 12.416667VW;
        margin-right: 5.791667VW;
    }.cpdd2{
        width: 10.416666666666668VW;
         margin-right: 3.641667VW;
    }.cpdd3{
        width: 10.416666666666668VW;
        margin-right: 3.458333VW;
    }.cpdd4{
        width: 20.46875VW;
    }

    .tablaDP{
         margin-top: 3.4375000000000004VW;
        width: 67.5VW;
        margin-left: 7.25VW;
    }.headerTPD{
        height: 5.677083333333333VW;
        background-color: #B70D1D;
        border-radius: 2.604166666666667VW 2.604166666666667VW 0vw 0vw;
    }.headerTPD p{
        width: 19.88645833333333VW;
        font-size: 1.875VW;
        font-family: "Rajdhani";
       color: var(--color-4);
       margin-left: 23.802083333333332VW;
       padding-top: 1.7708333333333333VW;
    }.headerTPD p span{
         color: var(--color-7);
         font-family: "Rajdhani";
    }.contentTPD{
        min-height: 35.208333333333336VW;
        background-color: #E5E5E5;
        margin-bottom: 5.052083333333333VW;
        padding-top: 2.9166666666666665VW;
        font-weight: 600;
        border-radius: 0vw 0vw 2.604166666666667VW 2.604166666666667VW;
    }

    .sec1_cnt{
        margin-bottom: 2.2916666666666665VW;
         font-size: 1.2583333333333333VW;
         font-family: "Rajdhani";
    }.hr_h{
        border: 1px solid #B70D1D;
       
    }.sec1_cnt{
        display: flex;
    }

    .sctitle_r{
        font-family: "Rajdhani";
        font-weight: 700;
        color: #B70D1D;
    }
    
    .dflx{
        display: flex;
    }

    .border_cnt{
        border-right: 0.11VW solid #B70D1D;
          margin-top: 1.2vw;
    }

     .border_cnt_b{
        border-bottom: 0.11VW solid #B70D1D;
        width: 55.51666666666667VW;
        margin-left: 5.989583333333334VW;
        margin-bottom: 2.8125VW;
    }

    .sec1_cnt_prd{
        width: 21.1453125VW;
        margin-left: 5.3125VW;
       
        
    }.cntt1{
          margin-right: 6.95VW;
    }

    .sec2_cnt_sub{
           width: 15.854167VW;
    }
    .item_cnt{
        padding-top: 0.8854166666666666VW;
    }.items_cnt p{
        width: 10.208333333333334VW;
        color: var(--color-1);
        margin-bottom: 0vw;
       
    }.pbl{
       font-family: "Rajdhani";
    }.item_cntprc{
        text-align: center;
    }


    .cntsub{
        margin-bottom: 4.53125VW;
    }
    .sec2_cnt_sub{
        margin-left: 2.604166666666667VW;
    }

    .sect2_cntt1{
         width: 7VW;
    }

    .cntmtd{
        margin-bottom: 2.96875VW;
    }

    .sec3_cnt_mtd{
        margin-left: 2.2916666666666665VW;
    }
    .sect3_cntt1{
           width: 6vw;
    }.sect3_cntt2{
          width: 8.708333VW;
    }

    .sec2_cnt_fac{
        margin-left: 5.3125VW;
          font-family: "Rajdhani";
          font-family: 1.25VW;
    }
    
    .fact1,.fact2{
        width: 11.393229166666668VW;
        margin-right: 3.4505208333333335VW;
    }
    .fact1 {
        margin-bottom: 1.3541666666666667VW;
        
    }
    .fact2{
       
       margin-bottom: 3.229166666666667VW;
    }
    
</style>