<template>
    <div class="DivDC">
      <form
        @submit.prevent="
          submit(
            data.name,
            data.lastname,
            data.email,
            file1,
            data.phone,
            password_form,
            passconfirm_form,
          )
        "
      >
        <div class="F1">
          <div class="">
            <p  for="inputN">Nombre</p>
            <input 
              v-model="data.name"
              id="inputN"
              required
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              type="text"
            />
          </div>
          <div>
            <p for="inputA">Apellido</p>
            <input 
              v-model="data.lastname"
              id="inputA"
              required
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              type="text"
            />
          </div>
        </div>
        <div class="F1">
          <div>
            <p for="inputT">Telefono</p>
            <input 
              v-model="data.phone"
              id="inputT"
              required
              minlength="10"
              maxlength="10"
              size="10"
              type="text"
            />
          </div>
          <div>
            <p for="inputE">Correo Electrónico</p>
            <input 
              v-model="data.email"
              id="inputE"
              required
              type="email"
            />
          </div>
          
          <div class="prueba">
            <p for="inputI">Imagen</p>
            <b-form-file placeholder="Seleccionar Archivo"
              v-model="file1"
              type="file"
              id="inputI"
              class="algo"
            />
        <p> <img class="img_position" :src="urlpath.url()+'/get-user-image/'+data.image" alt=""></p>
          </div>

        </div>
        <div class="F3">
          <p>Cambio de contraseña</p>
        </div>
        <div class="F4">
          
          <div>
            <p>Nueva Contraseña</p>
            <input 
              v-model="password_form"
              minlength="8"
              type="password"
              :placeholder="'***********'"
            />
          </div>
          <div>
            <p>Confirmar Nueva Contraseña</p>
            <input 
              v-model="passconfirm_form"
              minlength="8"
              type="password"
              :placeholder="'***********'"
            />
          </div>

          
        </div>
        <div class="F5">
          <div class="ConfirmButon">
            <button type="submit">
              <p>CONFIRMAR</p>
              <img src="../../assets/arrow-white.png">
            </button>
          </div>
        </div>
       
      </form>

     <div id="messages">
      
      <div class="Mensaje_Error" v-if="this.status == 'error'">
        <p>{{this.msg}}</p>
      </div>
      <div class="Mensaje_Suc" v-if="this.status == 'success'">
        <p>{{this.msg}}</p>
      </div>
     </div>
    </div>
</template>

<script>

import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import { mapActions } from "vuex";
let urlpath = require('../../global/url')

export default {
    name: 'cuenta',
    
  data() {
    return {
      password_form: "",
      passconfirm_form: "",
      status: "",
      msg: "",
      file1:null,
      msg: "success",
      urlpath
      

    };
  },
  
  created() {
    let payload = {
      id: this.user.sub,
      option: "user",
    };
    this.getInfoById(payload);
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },

components: {
    HeaderHome,
    Footer
  },
  computed:{    
    data() {
      let userdata = this.$store.getters["main/data"];
      return userdata;
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    

    
  },

  methods: {      
    ...mapActions("main", ["getInfoById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
      cambiarOpcion(option){
          this.option = option;
      },
      
    submit: async function (name, lastname, email, image, phone, password, passconfirm) {
       let id = this.user.sub;
        let rol = this.data.role

        id= String(id);

        if(password === passconfirm){
        this.status=''
        this.msg=''
             
         
       if(image !=null){
           
          //Creamos el formData
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('image', image);
          //Añadimos el método PUT dentro del formData
          // Como lo hacíamos desde un formulario simple _(no ajax)_
          data.append('_method', 'PUT');
          //Enviamos la petición
         let result = await   this.$store.dispatch("main/editImage", {id:id, data:data});
          if(result.data.status=='error'){
              this.status='error'
              this.msg= result.data.message
            }

          if(this.status != 'error'){
            let user = {
            id: id,
            name: name,
            lastname: lastname,
            email: email,
            phone:phone,
            password: password,
            role: rol,
          };

          
          let result = await  this.$store.dispatch("main/editItem",  {option:'user', item:user});
            
              if(result.status=='error'){
                this.status='error'
                this.msg= result.message
            this.delStatus()
              }else{ // success
                this.status='success'
                this.msg= "Datos actualizados correctamente."
                
            let payload = {
              id: user.id,
              option: "user",
            };
            let responseUser = await this.getInfoById(payload);
            localStorage.setItem('imgu', responseUser.image)
                this.delStatus()
              }
              
          }
           

      }else{
      let user = {
        id: id,
        name: name,
        lastname: lastname,
        email: email,
        phone:phone,
        password: password,
        role: rol,
      };

       
      let result = await  this.$store.dispatch("main/editItem",  {option:'user', item:user});
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
            this.delStatus()
          }else{ // success
            this.status='success'
            this.msg= "Datos actualizados correctamente."
            

            this.delStatus()
          }
      
      }
        }else{
              
                this.status='error'
                this.msg='Las contraseñas no coinciden.'
              }
            
     },
    delMsgs: function(){
       this.status=""
       this.msg=""
    },    
    delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,3000);
    },
    },

  
}
</script>

<style scoped>

.DivDC{
  margin-top: 8.385416666666666VW;
  margin-bottom: 47.96875VW;
}

.F1{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 3.0208333333333335VW;
}
  .F1 p{
    color: #032A4B;
    font-size: 0.8333333333333334VW;
    font-family: 'Rajdhani';
  }
  .F1 input{
    
    border: 1px solid #032A4B;
    width: 26.979166666666664VW;
    height: 3.6458333333333335VW;
    margin-right: 1.8229166666666667VW;
    padding: 0vw 1vw;
    font-size: 0.8333333333333334VW;
  }


.F2{
  display: flex;
}




.prueba b-form-file{
    background-color: black !important;
}



.F3{
  display: flex;
  width: 33vw;
  justify-content: right;
  margin-bottom: 3.0208333333333335VW;
}

.F3 p{
  font-size: 1.25VW;
  font-weight: 700;
  font-family: 'Rajdhani';
  color: #B70D1D;
}


.F4{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 5.989583333333334VW;
}
  .F4 p{
    color: #032A4B;
    font-size: 0.8333333333333334VW;
    font-family: 'Rajdhani';
  }
  .F4 input{
    
    border: 1px solid #032A4B;
    width: 17.34375VW;
    height: 3.6458333333333335VW;
    margin-right: 1.8229166666666667VW;
  }


.F5{
  width: 76.5vw;
  text-align: -webkit-right;
}
.ConfirmButon button{
  display: flex;
  width: 17.604166666666668VW;
  height: 4.166666666666666VW;
  font-size: 0.78125VW;
  background-color: #B70D1D;
  padding: 1.6vw 2vw;
  justify-content: space-between;
  border: 0;
  cursor: pointer;
}
  .ConfirmButon p{
    color: white;
  }
  .ConfirmButon img{
    height: .6vw;
    width: 2.8125VW;
    align-self: center;
  }

  .img_position{
    width: 12vw;
    height: auto;
    margin-top: 2vw;
    margin-left: 7vw;
  }


.Mensaje_Error{
    margin-top: 1vw;
    background-color: #a00b1a;
    color: white;
    width: 28.5vw;
    height: 3vw;
    text-align: center;
    padding-top: 0.5vw;
    font-size: 1.2vw;
    margin-left: 34vw;

}
.Mensaje_Suc{
    margin-top: 1vw;
    background-color: #117221;
    color: white;
    width: 28.5vw;
    height: 3vw;
    text-align: center;
    padding-top: 0.5vw;
    font-size: 1.2vw;
    margin-left: 34vw;

}




</style>
