<template>
    <div>

      <HeaderHome :name="user.fullname" />
      <div class="Cuenta_Nav">
          
          <div class="F1">
              <div class="F1C1">
                  <p> <img class="img_position" :src="urlpath.url()+'/get-user-image/'+ImgUser" alt=""></p>
                  
              </div>
              <div class="F1C2">
                  <div class="F1C2_F1"><p>¡Hola {{user.fullname}}!</p></div>
                  <!--<div class="F1C2_F2"><p>(¿No eres Dev Legrafica?  <span>Cerrar Sesión</span>)</p></div>-->
              </div>
          </div>

          <div class="F2">
              <p>MI CUENTA</p>
          </div>

          <div class="F3">
              <div class="F3C1">
                  <ul>
                      <li @click="cambiarOpcion('Escritorio')" :class="{red: this.option == 'Escritorio'}">ESCRITORIO</li>
                      <li @click="cambiarOpcion('Pedidos')" :class="{red: this.option == 'Pedidos'}">PEDIDOS</li>
                      <li @click="cambiarOpcion('Direccion')" :class="{red: this.option == 'Direccion'}">DIRECCIÓN</li>
                      <li @click="cambiarOpcion('Detalles')" :class="{red: this.option == 'Detalles'}">DETALLES DE LA CUENTA</li>
                      <li class="black" @click="logout()">SALIR</li>
                  </ul>
              </div>
          </div>
          
      </div>
      <div>
          <div v-if="option == 'Escritorio'">
              <Cuenta />
          </div>
          <div v-else-if="option == 'Pedidos'">
              <Pedidos />
          </div>
          <div v-else-if="option == 'Direccion'">
              <Direccion />
          </div>
          <div v-else-if="option == 'Detalles'">
              <Detalles />
          </div>
      </div>
    <Footer />

    </div>
    
</template>

<script>

import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import Cuenta from "./Cuenta";
import Pedidos from "./Pedidos";
import Direccion from "./Direccion";
import Detalles from "./Detalles";
import { mapActions } from "vuex";

let urlpath = require('../../global/url')

export default {
    name: 'homecuenta',
    data(){
        return{
            ImgUser:"",
            option: "Escritorio",
            urlpath
        }
    },
  created() {
    let payload = {
      id: this.user.sub,
      option: "user",
    };
    this.getInfoById2(payload);
    this.ImgUser = this.getImg();
  },
components: {
    HeaderHome,
    Footer,
    Cuenta,
    Pedidos,
    Direccion,
    Detalles,
  },
  methods: {
    ...mapActions("main", ["getInfoById2"]),
      logout: function(){
         
          delete localStorage.token;
          delete localStorage.ath; 
           this.wait()  
        },
         wait: function () {
        setTimeout(() => this.$router.push("/"), 200);
        },
      cambiarOpcion(option){
          this.option = option;
      },
      getImg(){
          return localStorage.getItem("imgu");
      }
  },
  computed:{
    data2() {
      return this.$store.getters["main/data2"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    
  },
async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },

}
</script>

<style scoped>

.red{
    background-color: #B70D1D;
}
.black{
    background-color: black;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
}
.nonred{
    background-color: transparent;
}

.Cuenta_Nav{
    background-image: url("../../assets/rectangulo_rojo.png");
    background-repeat: no-repeat;
    background-size: 100%;
}
    
    
.F1{
    display: flex;
    width: 30vw;
    height: 10vw;
    justify-content: right;
    align-items: center;
}

    .F1C1{
        width: 5.15625vw;
        height: 5.15625VW;
        margin-right: 1.5vw;
        overflow: hidden;
        border-radius: 50%;
    }
    .F1C1 img{
        width: auto;
        height: 5.15625VW;
    }
        .F1C2_F1 p{
            font-size: 1.5625VW;
            color: white;
            margin-bottom: 0vw;
            text-transform: capitalize;
        }
        .F1C2_F2 p{
            font-size: 0.9375VW;
            color: white;
        }
        .F1C2_F2 span{
            cursor: pointer;
            color: rgba(189, 201, 18, 0.555);
        }
        .F1C2_F2 span:hover{
            cursor: pointer;
            color: rgba(148, 158, 0, 0.555);
        }


.F2 {
    width: 100%;
    height: 7vw;
    text-align: center;
}

    .F2 p{
        color: white;
        font-weight: 800;
        font-size: 3.3854166666666665VW;
        font-family: 'Rajdhani';
    }

.F3C1 ul{
    display: flex;
    justify-content: center;
}

.F3{
    width: 100%;
    height: 7vw;
}
.F3C1 li{
    color: white;
    font-size: 1.0416666666666665VW;
    padding: 1vw 3vw;
    border: 1px solid white;
}



</style>
