<template>
    <div class="Cuenta_Empty">        
        <p>Desde el escritorio de tu cuenta puedes ver tus pedidos recientes, gestionar tus direcciones de envío y facturación y editar tu contraseña y los detalles de tu cuenta.</p>
        
    </div>
    
</template>

<script>

import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import { mapActions } from "vuex";

export default {
    name: 'cuenta',
components: {
    HeaderHome,
    Footer
  },
}
</script>

<style scoped>

.Cuenta_Empty{
  width: 100%;
  height: 50vw;
  padding-top: 10vw;
  text-align: -webkit-center;
  color: #909090;
  font-weight: 700;
  font-family: 'Rajdhani';
}

.Cuenta_Empty p{
  font-size: 1.3541666666666667VW;
    width: 45.2vw;
    text-align: -webkit-center;
}

</style>
