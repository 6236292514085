<template>
<div>
    <div v-if="this.option == 'Pedidos'" class="DivPedidos">

      <table v-if="data !='No se encontraron coincidencias'" class="Table_Pedidos">
        <tr>
          <th>PEDIDO</th>
          <th>FECHA</th>
          <th>ESTADO</th>
          <th>TOTAL</th>
          <th>ACCIONES</th>
        </tr>
        <tr v-for="(item, key) in filteredItems" :key="key" class="item_cont_tbl">
          <td>{{item.purchase_num}}</td>
          <td>{{item.purchase_date}}</td>
          <td>{{item.status}}</td>
          <td>$ {{item.total}}</td>
          <td><p @click="cambiarOpcion('Detalles', item.id)" class="BotonVer"><img src="../../assets/eye.png" />VER</p></td>
        </tr>
      </table>
      <div v-else>
    
  <p class="sinp">SIN PEDIDOS REGISTRADOS</p>
</div>
    </div>

    <div v-else>
      <DetallePedido :id="id" />
    </div>

    </div>


</template>

<script>

import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import DetallePedido from "./DetallePedido";
import { mapActions } from "vuex";
let urlpath = require('../../global/url')

export default {
    name: 'cuenta',
    data(){
      return{        
          id: "",
          currentPage: 1,
          itemsPerPage: 3,
            option: "Pedidos",
            urlpath
      }
    },
  created() {
    
    let payload = {
      email: this.user.email,
      option: "pedidos/email",
    };
    this.getByEmail(payload);
  },
components: {
    HeaderHome,
    Footer,
    DetallePedido
  },
  methods: {      
    ...mapActions("purchase", ["getByEmail"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
      cambiarOpcion(option, id){
          this.option = option;
          this.id = id;
      },
    
        // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
         submit: async function (name, num_seg) {
            let id = this.$route.params.id;
     

                this.status=''
                this.msg=''

                if(name =='ENVIADO' && num_seg =='SIN ASIGNAR'){
                        this.status='error'
                        this.msg= 'Inserte un numero de seguimiento valido.'
                }else{
                    let data = {
                                    id: id,
                                    estatus: name,
                                    num_seg: num_seg
                                    
                                };

                                
                                let result = await  this.$store.dispatch("main/editItem",  {option:'pedidos', item:data});
                                    
                                    if(result.status=='error'){
                                        this.status='error'
                                        this.msg= result.message
                                    }else{ // success
                                        this.showModal();
                                    }
                }
                    
            
           
      
         },
  },
  computed:{
    url() {
      return this.$store.getters["main/baseURL"];
    },
    data() {
      return this.$store.getters["purchase/data"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    
        allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    
  },
async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
}
</script>

<style scoped>

p.sinp{
  font-size: 2vw;
  color: #909090;
  font-family: 'Rajdhani';
}

.DivPedidos{
  width: 100%;
  height: fit-content;
  text-align: -webkit-center;
  margin: 8.75vw 0vw;
}

  .Table_Pedidos th{
    padding: 1.5vw 0vw;
    color: white;
    background-color: black;
    text-align: center;
    font-size: 1.0416666666666665VW;
    font-family: 'Rajdhani';
  }
  .Table_Pedidos td{
    padding: 2.5vw 4vw;
    color: #909090;
    font-size: 1.1458333333333333VW;
    font-family: Arial, Helvetica, sans-serif;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .BotonVer{
    width: 8vw;
    height: 3vw;
    text-align: center;
    margin-bottom: 0vw;
    padding-top: 0.9vw;
    color: white !important;
    background-color: rgb(156, 154, 17);
    font-size: 0.8333333333333334VW !important;
    cursor: pointer;
  }

  .BotonVer img{
    width: 1.2vw;
    height: auto;
    padding-bottom: 0.1vw;
    margin-right: 0.7vw;
  }





.sec1_cpd_titles{
        margin-top: 3.4895833333333335VW;
        display: flex;
           margin-left: 9.614583VW;
        color: var(--color-7);
    }.sec1_cpd_titles p{
        font-size: 1.25VW;
        font-weight: 700;
        text-align: center;
        text-decoration: underline;
    }.cpdt1{
        width: 14.531250000000002VW;
        margin-right: 7.291666666666667VW;
    }.cpdt2{
        width: 2.96875VW;
        margin-right: 11.041666666666666VW;
    }.cpdt3{
        width: 2.8125VW;
        margin-right: 11.458333333333332VW;
    }.cpdt4{
        width: 12.239583333333332VW;
    }.sec1_cpd_data{
        display: flex;
           margin-left: 9.614583VW;
        color: var(--color-1);
    }.sec1_cpd_data p{
        font-size: 1.25VW;
        text-align: center;
        font-family: "Rajdhani";
        font-weight: 600;
    }.cpdd1{
          width: 12.416667VW;
        margin-right: 5.791667VW;
    }.cpdd2{
        width: 10.416666666666668VW;
         margin-right: 3.641667VW;
    }.cpdd3{
        width: 10.416666666666668VW;
        margin-right: 3.458333VW;
    }.cpdd4{
        width: 20.46875VW;
    }





</style>
