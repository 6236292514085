<template>
    <div>
    <div class="DivDireccion" v-if="opcion =='Direcciones'">
        <div v-if="data !='No se encontraron coincidencias' && data != 'error'" class="Content_tbl">
          {{data}}
          <div class="F1">
            <div class="F1C1">
              <p class="C1">TÍTULO</p>
              <p class="C2">DIRECCIÓN</p>
              <p class="C1">CÓDIGO POSTAL</p>
              <p class="C1">CIUDAD</p>
              <p class="C1">ESTADO</p>
              <p class="C1">PAÍS</p>
            </div>
            <div class="F1C2">
              <p class="C3">EDITAR</p>
              <p class="C4">ELIMINAR</p>
            </div>
          </div>
          <div class="FI">
            <div v-for="(item, key) in filteredItems" :key="key" class="FIC1">
              <p class="C1">{{item.title}}</p>
              <p class="C2">{{item.direction}}</p>
              <p class="C1">{{item.cp}}</p>
              <p class="C1">{{item.city}}</p>
              <p class="C1">{{item.estate}}</p>
              <p class="C1">{{item.pais}}</p>
              <p class="C1" @click="cambiarOpcion('EditarDireccion', item.id)"><img src="../../assets/edit_1.png" alt="editar icon"></p>
              <p class="C1" @click="eliminarItem(item.id, 'direcciones')"><img src="../../assets/eliminar.png" alt="editar icon"></p>
            </div>
            <div class="FIC2">
              <div class="C3">
              </div>
              <div class="C4">
              </div>
            </div>
          </div>
        </div>
        <div v-else>          
  <p class="sinp">SIN DIRECCIONES REGISTRADAS</p>
        </div>
        <div @click="cambiarOpcion('AddDireccion')" class="btn_AddDirect">
          <img src="../../assets/plus_1.png">
          <p>AGREGAR NUEVA DIRECCIÓN</p>
        </div>
    </div>
<div v-else-if="opcion == 'EditarDireccion'">
<EditarDireccion :id="id" />
</div>
<div v-else-if="opcion == 'AddDireccion'">
<AddDireccion />
</div>
    </div>
</template>

<script>

import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import { mapActions } from "vuex";
import EditarDireccion from "../direccion/EditDireccion";
import AddDireccion from "../direccion/AddDireccion";

export default {
    name: 'direcciones',
    data(){ 
      return{
        currentPage: 1,
        itemsPerPage:   10,
        search_value:"",
        buscador_categoria:"",
        msg:"",
        status:"",
        isActive: true,
        hasError: false,
        opcion: "Direcciones",
        id: "",
      }
    },
async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  
    async created(){
      
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
    let user = this.$store.getters["admin/getIdentity"];
            this.getDireccionesByUser({option: "direccionesByUser", id_usuer:user.sub})
    },

components: {
    EditarDireccion,
    AddDireccion,
    HeaderHome,
    Footer
  },
  computed:{
        data() {
           return this.$store.getters["direcciones/data"];
        }, 
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    descStyle: function () {

    },

    
       //Paginacion
        allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    
  },
  
  methods: {      
    ...mapActions("direcciones", ["getDireccionesByUser"]),
    editarView: function(id){
            this.$router.push("/editar/direccion/"+id).catch(err => {});
        },
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
        showModalError() {
            this.$refs["modal-error"].show();
         },
      cambiarOpcion(value, id){
        this.id=id;
         return this.opcion = value;
      },


        eliminarItem: async function(id, option){
         //console.log(id + ' - '+ option)
        let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
        
         if(result.status=='error'){
           this.msg = result.message;
           this.showModalError();
         }else{
           this.msg='';
           this.wait()
         }
         
        },



  },






}
</script>

<style scoped>


p.sinp{
  font-size: 2vw;
  color: #909090;
  font-family: 'Rajdhani';
}

.DivDireccion{
  width: 100%;
  height: fit-content;
  text-align: -webkit-center;
  padding-top: 12.812499999999998VW;
  padding-bottom: 41.197916666666664VW;
}

.Content_tbl{
  width: 84.6875VW;
  height: fit-content;
}
  .F1{
    display: flex;
    width: 100%;
    height: 4.270833333333333VW;
  }
    .F1C1{
      display: flex;
      width: 76%;
      background-color: #032A4B;
      padding-top: 1.4vw;
    }
      .F1C1 p{
        color: white;
        font-size: 1.0416666666666665VW;
        font-weight: 700;
        font-family: 'Rajdhani';
      }
      .C1{
        width: 40%;
        text-align: center;
      }
      .C1 img{
        cursor: pointer;
        widows: auto;
        height: 1.3020833333333335VW;
      }
      .C2{
        width: 60%;
        text-align: center;
      }
      .C3{
        width: 50%;
        text-align: center;
      }
      .C4{
        width: 50%;
        text-align: center;
      }

    .F1C2{
      display: flex;
      width: 24%;
      background-color: #B70D1D;
      padding-top: 1.4vw;
    }
      .F1C2 p{
        color: white;
        font-size: 1.0416666666666665VW;
        font-weight: 700;
        font-family: 'Rajdhani';
      }



  .FI{
    height: fit-content;
    border: 1px solid #032A4B;
    padding: .7vw 0vw;
  }
    .FIC1{
      display: flex;
      width: 100%;
      padding: .6vw 0vw;
    }
    .FIC2{
      display: flex;
      width: 24%;
      align-self: center;
    }
      .FIC1 p{
        color: #909090;
        align-self: center;
        margin: 0;
      }
      .FIC2 img{
        widows: auto;
        height: 1.3020833333333335VW;
        cursor: pointer;
      }

.btn_AddDirect{
  width: 18.177083333333332VW;
  height: 3.6979166666666665VW;
  background-color: #968220;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 1vw 2vw;
  margin-top: 2.4479166666666665VW;
  margin-left: 66.5vw;
}
  .btn_AddDirect p{
    color: white;
    font-size: 0.8333333333333334VW;
    align-self: center;
    margin: 0;
  }
  .btn_AddDirect img{
    width: 0.78125VW;
    height: 0.78125VW;
    align-self: center;
  }


</style>
