<template>
    <div>
        <div class="Content_EditD">
            <form
              @submit.prevent="
                submit(
                 data.title,
                 data.direction,
                 data.cp,
                 data.city,
                 data.estate,
                 data.pais
                )
              "
            >
            <div class="F1">
                <div class="F1CI">
                    <label class="" for="inputN">Titulo</label>
                    <b-form-input
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                        v-model="data.title"
                        required
                        id="inputN"
                        type="text"
                    />
                </div>
                <div class="F1CI">
                    <label class="" for="inputN">Dirección</label>
                    <b-form-input
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                        v-model="data.direction"
                        required
                        id="inputN"
                        type="text"
                    />
                </div>
                <div class="F1CI">
                    <label class="" for="inputN">Codigo Postal</label>
                    <b-form-input
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                        v-model="data.cp"
                        required
                        id="inputN"
                        type="text"
                    />
                </div>
            </div>
            <div class="F2">
                <div class="F2C1">
                    <label class="" for="inputN">Ciudad</label>
                    <b-form-input
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                        v-model="data.city"
                        required
                        id="inputN"
                        type="text"
                    /></div>
                <div class="F2C2">
                    <label class="" for="inputN">Estado</label>
                    <select :disabled="loaded" class="Input_Solo" id="regionDE" name="regionDE" v-model="data.estate"  >
                                <option  v-for="(region, key) in  regiones" v-bind:key="key" v-bind:value="region">
                                  {{region}} 
                                </option>
                       </select> 
                </div>
            </div>
            <div class="F3">
                <div class="F3_Boton">
                    <b-btn 
                        variant="outline-secondary"
                        class="editar-modal "
                        type="submit"
                    >
                        GUARDAR
                    </b-btn>
                </div>
            </div>
            </form>

            <div id="messages">   

                <div class="Mensaje_Error" v-if="this.status == 'error'">
                    <p>{{this.msg}}</p>
                </div>

                <div class="Mensaje_Suc" v-if="this.status == 'success'">
                    <p>{{this.msg}}</p>
                </div>

            </div>

        </div>
    </div>
<!--

    <select :disabled="loaded" class="Input_Solo" id="regionDE" name="regionDE" v-model="data.estate"  >
                                <option  v-for="(region, key) in  regiones" v-bind:key="key" v-bind:value="region">
                                  {{region}} 
                                </option>
                       </select> 

-->

</template>

<script>

import { mapActions } from "vuex";

export default {
    name: 'EditarDireccion',
    props:{
        id:{
            type:Number,
            default: 0
        }
    },
  data() {
    return {
      status: "",
      msg: "",
      msgimg: "success",
      estate: "",
      regiones:[
      "Aguascalientes" ,
      "Baja California" ,
      "Baja California Sur" ,
      "Campeche" ,
      "Chiapas" ,
      "Chihuahua" ,
      "Ciudad de México" ,
      "Coahuila" ,
      "Colima ",
      "Durango" ,
      "Estado de México" ,
      "Guanajuato" ,
      "Guerrero" ,
      "Hidalgo" ,
      "Jalisco" ,
      "Michoacán" ,
      "Morelos" ,
      "Nayarit" ,
      "Nuevo León" ,
      "Oaxaca" ,
      "Puebla" ,
      "Querétaro" ,
      "Quintana Roo" ,
      "San Luis Potosí" ,
      "Sinaloa" ,
      "Sonora" ,
      "Tabasco" ,
      "Tamaulipas" ,
      "Tlaxcala" ,
      "Veracruz" ,
      "Yucatán" ,
      "Zacatecas" ,
    ] ,


    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
      
    let payload = {
      id: this.id,
      option: "direccionesById",
    };
    this.getDireccionesById(payload);
  },

  computed:{
      data() {
      return this.$store.getters["direcciones/data"];
    },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
  },

methods:{
    ...mapActions("direcciones", ["getDireccionesById"]),
    
    submit: async function (title, direction, cp, city, estate, pais) {
          let id = this.data.id;
          
          this.status=''
          this.msg=''

    

                var data = new  FormData();
                data.append('id', id);
                  data.append('title', title);
                  data.append('direction',direction);
                  data.append('cp',cp);
                  data.append('city',city);
                  data.append('estate',estate);
                  data.append('pais',pais);
                  data.append('_method', 'PUT');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'direcciones', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                    this.delStatus()
                  }else{ // success
                    this.status="success"
                    this.msg ="Dirección agregada con exito"
                    this.delStatus()
                  }
                

        

    },

    
    delMsgs: function(){
       this.status=""
       this.msg=""
    },    
    delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,3000);
    },
  

}

}
</script>

<style scoped>


.F1{
    display: flex;
    justify-content: center;
    margin: 4vw 0vw;
}
.F2{
    display: flex;
    justify-content: center;
}


.F1CI{
    margin-right: 2vw;
}
.F1CI label{
    color: #032A4B;
    font-size: 0.8333333333333334VW;
    font-family: 'Rajdhani';
}
.F1CI input{
    width: 25vw;
    height: 3vw;
    border: 1px solid #032A4B;
    border-radius: 0;
    font-size: 0.8333333333333334VW;
}


.F2C1{
    margin-right: 4vw;
}
.F2C1 label{
    color: #032A4B;
    font-size: 0.8333333333333334VW;
    font-family: 'Rajdhani';
}
.F2C1 input{
    width: 25vw;
    height: 3vw;
    border: 1px solid #032A4B;
    border-radius: 0;
    font-size: 0.8333333333333334VW;
}

.F2C2{
    display: grid;
}
.F2C2 label{
    color: #032A4B;
    font-size: 0.8333333333333334VW;
    font-family: 'Rajdhani';
}
.F2C2 select{
    width: 25vw;
    height: 3vw;
    border: 1px solid #032A4B;
    border-radius: 0;
    font-size: 0.8333333333333334VW;
}

.F3 {
    width: 89%;
    text-align: right;
    margin: 2vw 0vw;
}

.F3_Boton button{
    width: 9.84375VW;
    height: 3.75VW;
    color: white;
    background-color: #968220;
    border: 0;
    border-radius: 0;
}


.Mensaje_Error{
    margin-top: 1vw;
    margin-left: 34vw;
    margin-bottom: 1vw;
    background-color: #a00b1a;
    color: white;
    width: 28.5vw;
    height: 3vw;
    text-align: center;
    padding-top: 0.5vw;
    font-size: 1.2vw;

}
.Mensaje_Suc{
    margin-top: 1vw;
    margin-left: 34vw;
    margin-bottom: 1vw;
    background-color: #117221;
    color: white;
    width: 28.5vw;
    height: 3vw;
    text-align: center;
    padding-top: 0.5vw;
    font-size: 1.2vw;

}


</style>